import React from "react";
import './Page.css';

const ProjectPage = React.forwardRef((props, ref) => {
    return (
      <div className="page" ref={ref}>
        <h1 className="title-margin">{props.children}</h1>
        <div className="margin-sentences">
          <h3 className="sentences left-align">Sorting-Viz</h3>
          <ul>
            <li className="sentences left-align">Program that helps visualize how sorting algorithms work and the speed difference for each algorithm.</li>
            <li className="left-align"><a className="sentences left-align" target="_blank" href="https://github.com/creatorckim/sorting-viz">Github Link</a></li>
            <li className="left-align"><a className="sentences left-align" target="_blank" href="https://creatorckim.github.io/sorting-viz/">Working Link</a></li>
          </ul>
        </div>
        
       
      </div>
    );
  });

  export default ProjectPage;