import React from "react";
import './Page.css';

const AboutPage = React.forwardRef((props, ref) => {
    return (
      <div className="page" ref={ref}>
        <h1 className="title-margin">{props.children}</h1>
        <div className="margin-sentences">
        <p className="sentences">
          Hi, my name is Calvin and I am an experienced full-stack Software Engineer with 10+ years of experience in developing optimized and responsive software applications. With experience in a variety of current technologies and the desire to create clear and reliable software, I believe I can be a positive addition to my next position. 
        </p>

        </div>
      </div>
    );
  });

  export default AboutPage;