import React from "react";
import './Page.css';

const ExperiencePage2 = React.forwardRef((props, ref) => {
    return (
      <div className="page" ref={ref}>
        <h1 className="title-margin">{props.children}</h1>
        <div className="margin-sentences">
          <h3 className="sentences left-align">MaMa Chicken</h3>
          <ul>
            <li className="sentences left-align">A local Korean friend chicken that has over 200 orders daily.</li>
            <li className="sentences left-align">Consulting with clients to discuss ovjectives and functionality, illustrate wireframe, and implement functionality.</li>
          </ul>
        </div>
        
      </div>
    );
  });

  export default ExperiencePage2;