import React from "react";
import './Page.css';



const ContactPage = React.forwardRef((props, ref) => {
    return (
      <div className="page" ref={ref}>
        <h1 className="title-margin">{props.children}</h1>
        <div className='contact-sentences'>
          <div className="sentences">Thank you for reading! If you or anyone you know wants to communicate with me, please do not hesitate to reach out at any time.
          </div>
        </div>
        <div className="margin-sentences">
          <a className="sentences" href="mailto:calvinkim4@gmail.com">Email</a>

        </div>
      </div>
    );
  });

  export default ContactPage;