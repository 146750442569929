import React from "react";
import './Page.css';

const EducationPage = React.forwardRef((props, ref) => {
    return (
      <div className="page" ref={ref}>
        <h1 className="title-margin">{props.children}</h1>
        <div className="margin-sentences">
          <h3 className="sentences left-align">General Assembly, NYC</h3>
          <h3 className="sentences left-align">Software Engineering Immersive</h3>
          <ul>
            <li className="sentences left-align">12-week immersive program that includes 400+ hours of professional training by experienced software engineers.</li>
          </ul>
        </div>
        
      </div>
    );
  });

  export default EducationPage;