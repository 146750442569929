import React from "react";
import './Page.css';


const SkillsPage = React.forwardRef((props, ref) => {
    return (
      <div className="page" ref={ref}>
        <h1 className="title-margin">{props.children}</h1>
        <div className="skills-container margin-sentences">
          <div className='front-skills'>
            <ul>
              <li className="skill-list">React</li>
              <li className="skill-list">React Native</li>
              <li className="skill-list">Java</li>
              <li className="skill-list">C#</li>
              <li className="skill-list">Javascript</li>
              <li className="skill-list">Python</li>
              <li className="skill-list">HTML5/CSS3</li>
            </ul>
          </div>
          <div className='back-skills'>
            <ul>
              <li className="skill-list">Node.js</li>
              <li className="skill-list">Express.js</li>
              <li className="skill-list">SQL</li>
              <li className="skill-list">PostgreSQL</li>
              <li className="skill-list">Sequelize</li>
              <li className="skill-list">Git</li>
            </ul>
          </div>
        </div>
        
      </div>
    );
  });

  export default SkillsPage;