import React from "react";
import './Page.css';

const ProjectPage2 = React.forwardRef((props, ref) => {
    return (
      <div className="page" ref={ref}>
        <h1 className="title-margin">{props.children}</h1>
        <div className="margin-sentences">
          <h3 className="sentences left-align">WorkoutTracker</h3>
          <ul>
            <li className="sentences left-align">A mobile app that tracks daily workout routines.</li>
            <li className="sentences left-align">Built on React Native framework using Expo Go CLI which allowed quicker implementations and testing of hybrid apps (iOS and Android).</li>
            <li className="left-align"><a className="sentences left-align" target="_blank" href="https://github.com/creatorckim/workouttracker">Github Link</a></li>
          </ul>
        </div>
        
      </div>
    );
  });

  export default ProjectPage2;