import { useState, useEffect } from 'react';
import './App.css';
import HTMLFlipBook from 'react-pageflip';
import FrontPage from './components/FrontPage';
import AboutPage from './components/AboutPage';
import SkillsPage from './components/SkillsPage';
import ExperiencePage from './components/ExperiencePage';
import ExperiencePage2 from './components/ExperiencePage2';
import ProjectPage from './components/ProjectPage';
import ProjectPage2 from './components/ProjectPage2';
import EducationPage from './components/EducationPage';
import ContactPage from './components/ContactPage';
import useWindowDimensions from './useWindowDimensions';



function App() {
  const { height, width } = useWindowDimensions();
  const [num, setNum] = useState(0);
  
  useEffect(() => {
    if (width < height) {
      setNum(1.3);
    } else {
      setNum(2.5);
    };
  }, []);

  return (
    <>
      <div className="help-page">
        <div>
          <h3 class="help-text">Welcome to my comic book portfolio</h3>
          <h3 class="help-text">Click on the pages to turn</h3>
          {/* <h3 class="help-text">Click on the pages to turn ---&gt;</h3> */}
        </div>
      </div>
      <div className='flipbook-container'>
        <div className='flip-book'>
          <HTMLFlipBook size="fixed" width={width / num} height={width / num} showCover={true}>
            <FrontPage className="page">FRONT PAGE</FrontPage>
            <AboutPage className="page">ABOUT ME</AboutPage>
            <SkillsPage className="page">SKILLS</SkillsPage>
            <ExperiencePage className="page">EXPERIENCE</ExperiencePage>
            <ExperiencePage2 className="page">EXPERIENCE CONT.</ExperiencePage2>
            <ProjectPage className="page">PROJECTS</ProjectPage>
            <ProjectPage2 className="page">PROJECTS CONT.</ProjectPage2>
            <EducationPage className="page">EDUCATION</EducationPage>
            <ContactPage className="page">CONTACT</ContactPage>
          </HTMLFlipBook>
        </div>
      </div>
    </>
  );
}

export default App;
