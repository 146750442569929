import React from "react";
import './Page.css';

const ExperiencePage = React.forwardRef((props, ref) => {
    return (
      <div className="page" ref={ref}>
        <h1 className="title-margin">{props.children}</h1>
        <div className="margin-sentences">
          <h3 className="sentences left-align">DaeBark Catering</h3>
          <a className="sentences left-align" target="_blank" href="https://daebarkcatering.com/">daebarkcatering.com</a>
          <ul>
            <li className="sentences left-align">Responsible for the implementation of the application's navigation, site optimization, code performance, and design.</li>
            <li className="sentences left-align">Averages over 3000+ viewers per month.</li>
          </ul>
        </div>
        
        
      </div>
    );
  });

  export default ExperiencePage;