import React from "react";
import './Page.css';
import image from '../img/code.jpg'

const FrontPage = React.forwardRef((props, ref) => {
    return (
      <div className="page" ref={ref}>
        <h1 className="name">CALVIN KIM</h1>
        <h2 className="title">FULL STACK SOFTWARE ENGINEER</h2>
        <div className="image-container">
          <img className='front-image' src={image} alt="code"></img>

        </div>
      </div>
    );
  });

  export default FrontPage;